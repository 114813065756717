import React, { FC, RefObject, useState, useEffect, useRef, ChangeEvent, HTMLAttributes } from 'react'
import { DropdownOption } from '@/root/shared-types'
import cn from 'classnames'
import { useCustomRouter } from '@/root/shared-hooks'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'
import { GroupBadge } from '@/root/ui/admaritime'
import { noop } from 'lodash'

interface RouteDropdownProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  handleToggleDropdown: () => void
  options: DropdownOption[]
  handleOptionSelection: (id: DropdownOption) => void
  dropdownRef: RefObject<HTMLDivElement>
  placeHolder: string
  testId?: string
  selectedOption?: DropdownOption
  selectedOptions?: DropdownOption[]
  disableFilter?: boolean
  displaySelectedOptions?: boolean
  variant?: 'default' | 'filterSelected'
  contextClass?: string
}

const Dropdown: FC<RouteDropdownProps> = ({
  isOpen,
  handleToggleDropdown,
  selectedOption,
  options,
  handleOptionSelection,
  dropdownRef,
  placeHolder,
  className,
  testId,
  contextClass,
  selectedOptions = [],
  disableFilter = false,
  displaySelectedOptions = true,
  variant = 'default',
}) => {
  const { locale } = useCustomRouter()

  const [inputValue, setInputValue] = useState<string>('')
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
  const optionRefs = useRef<(HTMLParagraphElement | null)[]>([])
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const dropdownListRef = useRef<HTMLDivElement>(null)

  const filteredOptions = disableFilter
    ? options
    : options.filter((option) =>
        (option.name[locale] ?? option.name['en']).toLowerCase().includes(inputValue.toLowerCase())
      )

  const availableOptions =
    variant === 'filterSelected'
      ? filteredOptions.filter((option) => !selectedOptions.some((selected) => selected.id === option.id))
      : filteredOptions

  const autoResizeTextarea = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  const handleOptionClick = (option: DropdownOption) => {
    handleOptionSelection(option)

    if (variant === 'filterSelected') {
      setInputValue('')
    } else {
      setInputValue(option.name[locale] || '')
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
    autoResizeTextarea(e.target)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!isOpen) return

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex < availableOptions.length - 1 ? prevIndex + 1 : 0))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : availableOptions.length - 1))
    } else if (e.key === 'Enter') {
      e.preventDefault()
      if (highlightedIndex >= 0) {
        handleOptionSelection(availableOptions[highlightedIndex])
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      handleToggleDropdown()
    }
  }

  useEffect(() => {
    if (!isOpen && selectedOption) {
      setInputValue(selectedOption.name[locale] || '')
    }
  }, [selectedOption, isOpen, locale])

  useEffect(() => {
    if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
      optionRefs.current[highlightedIndex]?.scrollIntoView({ block: 'nearest' })
    }
  }, [highlightedIndex])

  useEffect(() => {
    if (!isOpen) {
      setInputValue(selectedOption?.name[locale] || '')
      setHighlightedIndex(-1)
    }
  }, [locale, isOpen, selectedOption])

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea(textareaRef.current)
    }
  }, [inputValue])

  return (
    <div
      className={cn(
        'relative w-full',
        contextClass !== 'route-search-dropdown' && 'border-neutral-normal-lighter border rounded'
      )}
      ref={dropdownRef}
    >
      {!selectedOptions.length ? (
        <textarea
          ref={textareaRef}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeHolder}
          onClick={() => {
            setInputValue('')
            handleToggleDropdown()
          }}
          className={cn(
            'flex w-full cursor-text resize-none items-center outline-none border-0 text-primary-base',
            contextClass === 'route-search-dropdown'
              ? 'ps-5 placeholder:text-neutral-medium-light'
              : 'p-3 placeholder:text-neutral-darkest overflow-hidden',
            className
          )}
          rows={1}
          readOnly={!isOpen || disableFilter}
          onKeyDown={disableFilter ? noop : handleKeyDown}
          data-testid="selected-options"
        />
      ) : (
        <span
          className="border-neutral-normal-lighter flex min-h-10 w-full rounded p-3"
          onClick={handleToggleDropdown}
          data-testid="selected-options"
        >
          <div className="grid w-full grid-cols-[95%_5%]">
            <span className="flex flex-wrap items-center gap-3">
              {!displaySelectedOptions && <p>{placeHolder}</p>}
              {displaySelectedOptions &&
                selectedOptions.map((option) => <GroupBadge title={option.name['en']} key={option.id} />)}
            </span>
            <ChevronDownIcon />
          </div>
        </span>
      )}

      {isOpen && availableOptions.length > 0 && (
        <div
          ref={dropdownListRef}
          className={cn(
            'border-neutral-normal-lighter absolute left-0 top-full z-50 w-full border-t overflow-y-auto rounded  bg-white shadow-md',
            {
              'max-h-96 border-t-0': contextClass === 'route-search-dropdown',
            }
          )}
          data-testid={testId}
        >
          {availableOptions.map((option, index) => (
            <p
              key={option.id}
              ref={(el) => {
                optionRefs.current[index] = el
              }}
              className={cn(
                'min-h-10 w-full cursor-pointer py-2 ps-5 text-base-darkest transition-colors duration-300',
                {
                  'bg-base-lightest': highlightedIndex === index,
                  'hover:bg-base-lightest': highlightedIndex !== index,
                }
              )}
              onClick={() => handleOptionClick(option)}
            >
              {option.name[locale] || option.name['en']}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default Dropdown

import { CaptionProps, useNavigation } from 'react-day-picker'
import { format } from 'date-fns'
import { dayPickerFormats } from '@/root/shared-consts'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'
import { useCustomRouter } from '@/root/shared-hooks'
import { enUS, es, ar } from 'date-fns/locale'

export const CustomNavbar = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const { locale } = useCustomRouter()

  const dateFnsLocale = getDateFnsLocale(locale)

  return (
    <div className="flex w-full justify-between px-3 text-base">
      <h2>{format(props.displayMonth, dayPickerFormats.general, { locale: dateFnsLocale }).toUpperCase()}</h2>
      <span className="flex gap-5">
        <button disabled={!previousMonth} onClick={() => previousMonth && goToMonth(previousMonth)}>
          <ChevronDownIcon width={14} height={14} color="secondary" className="rotate-90 rtl:-rotate-90" />
        </button>
        <button disabled={!nextMonth} onClick={() => nextMonth && goToMonth(nextMonth)}>
          <ChevronDownIcon width={14} height={14} color="secondary" className="-rotate-90 rtl:rotate-90" />
        </button>
      </span>
    </div>
  )
}

const getDateFnsLocale = (locale: string) => {
  switch (locale) {
    case 'es':
      return es
    case 'ar':
      return ar
    default:
      return enUS
  }
}

import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import {
  useAuth,
  useCustomRouter,
  useGetAuthToken,
  useGetPassengersData,
  useGetTranslations,
} from '@/root/shared-hooks'
import { HeaderNavBarProps } from '@/root/shared-types'
import { Button } from '@/root/ui/admaritime'
import { ContentContainer } from '@/root/components/shared'
import Link from 'next/link'
import Image from 'next/image'
import { APP_ROUTES } from '@/root/shared-consts'
import { Hamburger } from '@/root/ui/admaritime/Hamburger/Hamburger'
import { fetchCompaniesAndPassengers } from '@/root/shared-hooks/useGetPassengersData/useGetPassengersData'

interface MainHeaderNavBarProps extends HeaderNavBarProps {
  mobileMenuOpen: boolean
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>
}

const MainHeaderNavBar: FC<MainHeaderNavBarProps> = ({ menuItems, mobileMenuOpen, setMobileMenuOpen }) => {
  const { getTranslation } = useGetTranslations()
  const { isAuth } = useGetAuthToken()
  const { logOut } = useAuth()
  const { push } = useCustomRouter()
  const { data, mutate } = useGetPassengersData(isAuth)

  useEffect(() => {
    if (isAuth) {
      mutate(fetchCompaniesAndPassengers(), false)
    }
  }, [isAuth, mutate])

  const fullName = data?.passengers?.[0]?.fullName

  const logoAdm = '/admaritime/images/logo-admaritime-v3.png'
  const logoMobility = '/admaritime/images/logo-mobility-transparent.png'

  return (
    <div className="bg-white">
      <ContentContainer className="flex justify-between gap-4 p-5">
        <div className="darken flex min-w-60 grow basis-0 flex-wrap items-center gap-4">
          <Link href="/">
            <Image alt="admaritime logo" src={logoAdm} width="275" height="47" />
          </Link>
          <Link href="/">
            <Image alt="Abu Dhabi logo" src={logoMobility} width="196" height="84" />
          </Link>
        </div>

        <nav className="flex flex-col justify-center">
          {!mobileMenuOpen && <Hamburger onClick={() => setMobileMenuOpen(true)} />}

          <ul className="text-primary-base hidden items-center gap-5 text-base font-bold lg:flex lg:gap-7">
            {menuItems.map(({ id, link, subject, className }) => {
              if (className === 'login') {
                return (
                  <li key={id} className="shrink-0">
                    {isAuth ? (
                      <div className="flex items-center">
                        <li className="darken border-secondary me-7 flex border-b-2 text-start lg:ms-0 lg:text-center ">
                          <Link href={APP_ROUTES.details}>{getTranslation('profilePageTitle')}</Link>
                        </li>
                        <div className="relative">
                          <Button variant="contained" onClick={logOut}>
                            {getTranslation('headerLogoutButtonLabel')}
                          </Button>
                          <p className="text-secondary absolute end-0 mt-3 whitespace-nowrap text-end text-xs font-bold">
                            {fullName ? (
                              <>
                                {getTranslation('headerLoginButtonLabel')} <span className="uppercase">{fullName}</span>
                                !
                              </>
                            ) : (
                              getTranslation('headerLoginBtnUserUndefined')
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <Button variant="contained" onClick={() => push(APP_ROUTES.signup)}>
                        {getTranslation('headerRegisterOrLoginButton')}
                      </Button>
                    )}
                  </li>
                )
              }

              return (
                <li key={id} className="darken text-center">
                  <Link href={link}>{subject}</Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </ContentContainer>
    </div>
  )
}

export default MainHeaderNavBar

import { ContentContainer } from '@/root/components/shared'
import { Icon } from '@/root/ui/admaritime'
import { FC } from 'react'
import Link from 'next/link'
import { MenuItem, SocialFooterItem, LinkToGroupItem } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'

interface FooterSocialProps {
  socialItems: SocialFooterItem[]
  menuItems: MenuItem[]
  linkToGroupItems: LinkToGroupItem[]
}

const FooterSocial: FC<FooterSocialProps> = ({ socialItems, menuItems, linkToGroupItems }) => {
  const currentYear = new Date().getFullYear()
  const { getTranslation } = useGetTranslations()
  const [firstItem = { label: 'PART OF', link: 'https://www.adportsgroup.com/en', title: 'AD PORTS GROUP' }] =
    linkToGroupItems

  return (
    <div className="bg-base-dark">
      <ContentContainer>
        <div className="flex flex-col justify-between gap-6 px-5 py-8 text-white sm:flex-row sm:items-center">
          <div className="shrink-1 flex min-w-32 flex-col gap-1 text-xl md:shrink-0">
            <span className="font-normal">{firstItem.label}</span>
            <Link className="font-bold" href={firstItem.link} target="_blank">
              {firstItem.title}
            </Link>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex items-center justify-center gap-3 sm:justify-end">
              {socialItems.map(({ link, icon, title }) => (
                <Link className="darken" key={link} href={link} target="_blank">
                  {icon && <Icon ignoreRtl alt={icon} height={32} width={32} type={icon} />}
                  {title ? title : null}
                </Link>
              ))}
            </div>
            <div className="flex flex-col items-center justify-end gap-4 text-center text-sm sm:flex-row">
              <div className="text-base-very-light">{`©${currentYear} ${getTranslation('footerCopyright')}`}</div>
              <div className="flex flex-col items-center gap-2 xs:flex-row xs:gap-4">
                {menuItems.map(({ id, link, subject }) => (
                  <Link key={id} className="darken" href={link}>
                    {subject}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  )
}

export default FooterSocial

import React, { FC } from 'react'
import {
  BicycleIcon,
  BidirectionIcon,
  CarIcon,
  PassengerIconCyan,
  TruckIconCyan,
  WaterTaxiIcon,
} from '@/root/ui/shared'

interface RouteInfoProps {
  locationFrom: string
  locationTo: string
  isTaxi: boolean
  supportTrucks: boolean
  supportCars: boolean
  supportPassengers: boolean
  supportBicycles: boolean
}

const RouteInfo: FC<RouteInfoProps> = ({
  locationFrom,
  locationTo,
  isTaxi,
  supportTrucks,
  supportPassengers,
  supportCars,
  supportBicycles,
}) => {
  return (
    <div className="flex basis-1/2 flex-col items-center justify-evenly text-base font-bold">
      <div>{locationFrom} </div>
      <BidirectionIcon width={20} />
      <div>{locationTo} </div>

      <div className="flex h-8 items-center justify-center gap-2.5">
        {isTaxi ? (
          <WaterTaxiIcon />
        ) : (
          <>
            {supportPassengers && <PassengerIconCyan width={11} />}
            {supportBicycles && <BicycleIcon width={32} color="secondary" />}
            {supportCars && <CarIcon width={36} className="-mb-0.5" />}
            {supportTrucks && <TruckIconCyan width={28} />}
          </>
        )}
      </div>
    </div>
  )
}

export default RouteInfo

import type { CartItemSailParam } from '@/pages/select-tickets/[packages]/[sailRefIds]'
import { PassengerDetailsState } from '@/app/components/PassengerDetailsDialog'
import { VehicleDetailsState } from '@/app/components/VehicleDetailsDialog'

export interface GuestSlot extends PassengerDetailsState {
  seqNum: number
  passengerId?: string
}

export type VehicleSlot = {
  seqNum: number
} & VehicleDetailsState

export type CartItem = {
  quantity: number
  title: string
  price: number
  priceCategory: string
  type: string
  subType: string
  slots: Array<GuestSlot | VehicleSlot>
  sailPackages: Array<CartItemSailParam>
  licencePlate?: string
}

export type Gender = 'M' | 'F'

export type AddCartItem = Omit<CartItem, 'slots'> & {
  passengerId?: string
  licencePlate?: string
} & PassengerDetailsState

interface RequestBase {
  quantity: number
  priceCategory: string
  sailPackages: Array<CartItemSailParam>
}

interface PassengerTicketRequest extends RequestBase {
  passengers: Array<{ seqN: number }>
}

interface VehicleTicketRequest extends RequestBase {
  vehicles: Array<{ seqN: number } & VehicleDetailsState>
}

export interface RequestDTO {
  passengerTickets?: Array<PassengerTicketRequest>
  vehicleTickets?: Array<VehicleTicketRequest>
}

export interface BoraRequestError {
  code: string
  status?: number
  detail?: string
  id?: string
}

export type ItemClass = 'passengers' | 'vehicles' | 'addons' | 'bicycles'

export type BookingCartState = {
  nextSeqNum: number
  totalPrice: string
  currentRequest: RequestDTO
  reservationId?: number
  reservationToken?: string
  additionalInfo?: string
} & Record<ItemClass, Array<CartItem>>

export const defaultState: BookingCartState = {
  addons: [],
  bicycles: [],
  vehicles: [],
  passengers: [],
  totalPrice: '0',
  nextSeqNum: 0,
  currentRequest: {},
}

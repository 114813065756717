import { FC, useEffect, useState } from 'react'
import { DayPickerProps, Inventory, RouteInfoCardProps } from '@/root/shared-types'
import {
  AddReturnRouteButton,
  AvailableCapacityTable,
  ChangeDateButton,
  hasFutureSail,
  NoMoreSailsNotice,
  RouteInfoCard,
} from '@/root/modules/shared/SelectDateTimePage'
import { Divider, Loader } from '@/root/ui/shared'
import { useAppConfig, useGetTranslations } from '@/root/shared-hooks'
import { timers } from '@/root/shared-consts'
import { DateTime } from 'luxon'
import cn from 'classnames'

interface RouteInfoSectionProps extends RouteInfoCardProps, DayPickerProps {
  inventory: Inventory
  isLoading: boolean
  sailRefId: number
  setRefIdAndTime: (sailRefId: number, time: string, isNextDay: boolean) => void
  time: string
  isNextDay: boolean
  dataTestId?: string
  hasDifferentShips?: boolean
  hideRoundtripButton?: boolean
  initialTimestamp?: DateTime
  showDivider?: boolean
  routeAttributes?: Record<string, boolean | string>
  preventUndefinedPackageID?: boolean
}

export const RouteInfoSection: FC<RouteInfoSectionProps & { dataTestId?: string }> = ({
  routeInfo,
  hideRoundtripButton = false,
  showDivider = false,
  showCancelButton = false,
  setHideRoundTripButton,
  swapPorts,
  selectedDate,
  onDayClick,
  isDayDisabled,
  time,
  isNextDay,
  sailRefId,
  setRefIdAndTime,
  inventory,
  isLoading,
  hasDifferentShips,
  routeAttributes,
  dataTestId = 'departure-card',
  initialTimestamp,
  preventUndefinedPackageID = true,
}) => {
  const { getTranslation } = useGetTranslations()
  const { defaultTimezone } = useAppConfig()

  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isLoading) {
      timer = setTimeout(() => setShowLoader(true), timers.LOADER_TIMEOUT)
    } else {
      setShowLoader(false)
    }

    return () => clearTimeout(timer)
  }, [isLoading])

  const isPrivateService = routeAttributes?.['PRIVATE_SERVICE_ENABLED']
  const hideButtonAttribute = routeAttributes?.['HIDE_ROUNDTRIP_BUTTON']

  const hasUpcomingSail = hasFutureSail({ defaultTimezone, inventory, initialTime: initialTimestamp })

  const showAddReturnRouteButton = preventUndefinedPackageID && !hideButtonAttribute && !hideRoundtripButton

  return (
    <>
      {showDivider && (
        <div className="relative mx-5 my-3.5 sm:mx-10">
          <Divider variant={'light'} />
        </div>
      )}
      <section data-testid={dataTestId} className={cn('px-2 pb-5 xs:px-5', { 'pt-12': showDivider })}>
        {!showDivider && (
          <div className="flex h-14 items-center">
            {hasDifferentShips && hasUpcomingSail && (
              <p className="text-error grow px-3 text-center text-base xs:px-5">{getTranslation('infoReturnTicket')}</p>
            )}
          </div>
        )}
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="flex flex-col gap-5 px-3 xs:px-5 lg:min-w-96 xl:min-w-400">
            <ChangeDateButton
              selectedDate={selectedDate}
              onDayClick={onDayClick}
              isDayDisabled={isDayDisabled}
              time={time}
              isNextDay={isNextDay}
            />
            <RouteInfoCard
              routeInfo={routeInfo}
              showCancelButton={showCancelButton}
              setHideRoundTripButton={setHideRoundTripButton}
              swapPorts={swapPorts}
              showSwapPorts={preventUndefinedPackageID && !hideButtonAttribute}
            />
            {showAddReturnRouteButton && <AddReturnRouteButton setHideRoundTripButton={setHideRoundTripButton} />}
          </div>

          <div className="flex grow flex-col items-center gap-3 sm:px-5 lg:gap-1">
            {!showDivider && isPrivateService && hasUpcomingSail && (
              <p className="text-primary-base px-3 text-center text-base font-normal normal-case xs:px-5 sm:px-0">
                {getTranslation('privateServiceTitle')}
              </p>
            )}

            {showLoader ? (
              <Loader />
            ) : !isLoading ? (
              hasUpcomingSail ? (
                <AvailableCapacityTable
                  initialTimestamp={initialTimestamp}
                  inventory={inventory}
                  routeAttributes={routeAttributes}
                  sailRefId={sailRefId}
                  setRefIdAndTime={setRefIdAndTime}
                />
              ) : (
                <NoMoreSailsNotice />
              )
            ) : null}
          </div>
        </div>
      </section>
    </>
  )
}

import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

export const BottomSliderContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => (
  <div
    data-testid="bottom-slider"
    className={cn('mb-8 flex flex-col items-center gap-8 p-4 lg:mb-0 lg:flex-row lg:justify-between lg:p-7', className)}
  >
    {children}
  </div>
)

import { CloseIcon } from '@/root/ui/shared/icons/CloseIcon'
import { FC, HTMLAttributes, useEffect } from 'react'

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  onClose: () => void
}

export const Modal: FC<ModalProps> = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      window.addEventListener('keydown', handleKeyDown)
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }

  return (
    <div
      className="fixed min-w-[390px] inset-0 z-50 flex items-start justify-center bg-black bg-opacity-85"
      onClick={handleOverlayClick}
    >
      <div className="absolute sm:relative left-0 top-0 sm:top-[15%] sm:mx-auto w-min-[380px] sm:w-auto w-full h-full sm:h-auto max-w-screen-lg bg-white p-8 pb-10 shadow-lg ">
        <div className="flex justify-end pb-2">
          <button onClick={onClose}>
            <CloseIcon color="neutral-medium-light" />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

import { FC, SetStateAction, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import {
  SET_AVAILABLE_DATES,
  SET_DATE,
  SET_HIDE_ROUND_TRIP_BUTTON,
  SET_INVENTORY,
  SET_LOADING,
  SET_NEXT_INVENTORY,
  SET_NEXT_SAIL_CONTENT,
  SET_PORTS,
  SET_REF_ID_AND_TIME,
  SET_SAIL_CONTENT,
} from '@/root/modules/shared/SelectDateTimePage/state/actions'
import {
  useAppConfig,
  useCustomRouter,
  useErrorBanner,
  useGetAvailableDates,
  useGetInventory,
  useGetSailPackage,
  useGetSails,
  useGetTranslations,
} from '@/root/shared-hooks'
import {
  getInitialTimestamp,
  getModifiedInventory,
  getTravelDuration,
  getVessel,
  getVesselTitleLocalized,
  hasDifferentVessels,
  RouteInfoSection,
  SelectTicketButton,
  setEarliestTimeAndRefId,
  reverseTitleParts,
} from '@/root/modules/shared/SelectDateTimePage'
import { checkIsDayDisabled } from '@/root/shared-helpers'
import { Direction, SailPackage } from '@/root/shared-types'
import { BottomSliderContainer, GoBackButton } from '@/root/components/shared'
import { SailParam } from '@/root/modules/shared/SelectDateTimePage/helpers/parseSailPackages'
import { selectDateTimeReducer } from '@/root/modules/shared/SelectDateTimePage/state/reducer'
import { SelectDateTimeState } from '@/root/modules/shared/SelectDateTimePage/state/types'
import { defaultInventory, regexPatterns } from '@/root/shared-consts'
import { DateTime } from 'luxon'
import * as R from 'ramda'

interface SelectDateTimeContentProps {
  sailParams: Array<SailParam>
  routeBaseInfo?: SailPackage
}

type UpdateParamRecord = Partial<Record<'dates' | 'packages', string | string[]>>

const convertToDateOnly = (date: Date) => String(DateTime.fromJSDate(date).toISODate())

const { separatorDash } = regexPatterns

export const SelectDateTimeContent: FC<SelectDateTimeContentProps> = ({ routeBaseInfo, sailParams }) => {
  const fallbackPackageId = routeBaseInfo?.backwardSailPackage ?? ''

  const { locale, replace, query } = useCustomRouter()
  const { setSailPackage } = useGetSailPackage()
  const { defaultTimezone } = useAppConfig()
  const { errorBody, errorHandler } = useErrorBanner()
  const { getTranslation } = useGetTranslations()

  const updateUrlOnly = useCallback(
    async (updateRecord: UpdateParamRecord) => {
      await replace(
        {
          query: { ...query, ...updateRecord },
        },
        undefined,
        { shallow: true }
      )
    },
    [replace, query]
  )

  const [packageIdThere, packageIdBack] = [sailParams[0]?.code, sailParams[1]?.code || fallbackPackageId]
  const [dateTo, dateFrom] = [sailParams[0]?.sailDates[0], sailParams[1]?.sailDates[0]]

  const availableDatesTo = useGetAvailableDates({ sailPackage: String(packageIdThere) })
  const availableDatesFrom = useGetAvailableDates({ sailPackage: String(packageIdBack) })

  const today = new Date()
  const toDate = dateTo ? new Date(String(dateTo)) : today
  const fromDate = dateFrom ? new Date(String(dateFrom)) : toDate

  const initialState: SelectDateTimeState = {
    sail: {
      there: {
        date: toDate,
        availableDates: availableDatesTo,
        port: '',
        inventory: defaultInventory,
        nextInventory: defaultInventory,
        sailContent: [],
        nextSailContent: [],
        loading: true,
        sailRefId: -1,
        time: '',
        isNextDay: false,
      },
      back: {
        date: fromDate,
        availableDates: availableDatesFrom,
        port: '',
        inventory: defaultInventory,
        nextInventory: defaultInventory,
        sailContent: [],
        nextSailContent: [],
        loading: true,
        sailRefId: -1,
        time: '',
        isNextDay: false,
      },
    },
    hideRoundTripButton: Boolean(dateFrom),
  }

  const [state, dispatch] = useReducer(selectDateTimeReducer, initialState)
  const { there, back } = state.sail

  const [dateThere, dateBack] = [there.date, back.date].map(convertToDateOnly)
  const [nextDateThere, nextDateBack] = [there.date, back.date].map((date) =>
    String(DateTime.fromJSDate(date).plus({ days: 1 }).toISODate())
  )

  const {
    inventory: inventoryThere,
    error: invErrorThere,
    isLoading: invLoadingThere,
  } = useGetInventory({
    locale,
    sailPackage: packageIdThere,
    date: dateThere,
  })

  const {
    inventory: nextInventoryThere,
    error: nextInvErrorThere,
    isLoading: nexInvLoadingThere,
  } = useGetInventory({
    locale,
    sailPackage: packageIdThere,
    date: nextDateThere,
  })

  const {
    inventory: inventoryBack,
    error: invErrorBack,
    isLoading: invLoadingBack,
  } = useGetInventory({
    locale,
    sailPackage: packageIdBack,
    date: dateBack,
  })

  const {
    inventory: nextInventoryBack,
    error: nextInvErrorBack,
    isLoading: nextInvLoadingBack,
  } = useGetInventory({
    locale,
    sailPackage: packageIdBack,
    date: nextDateBack,
  })

  const {
    sailContent: sailContentThere,
    error: sailErrorThere,
    isLoading: sailLoadingThere,
  } = useGetSails({
    sailPackage: packageIdThere,
    date: dateThere,
  })

  const {
    sailContent: nextSailContentThere,
    error: nextSailErrorThere,
    isLoading: nextSailLoadingThere,
  } = useGetSails({
    sailPackage: packageIdThere,
    date: nextDateThere,
  })

  const {
    sailContent: sailContentBack,
    error: sailErrorBack,
    isLoading: sailLoadingBack,
  } = useGetSails({
    sailPackage: packageIdBack,
    date: dateBack,
  })

  const {
    sailContent: nextSailContentBack,
    error: nextSailErrorBack,
    isLoading: nextSailLoadingBack,
  } = useGetSails({
    sailPackage: packageIdBack,
    date: nextDateBack,
  })

  const prevErrorRef = useRef<boolean>()

  useEffect(() => {
    const error = [
      invErrorThere,
      invErrorBack,
      sailErrorThere,
      sailErrorBack,
      nextInvErrorThere,
      nextInvErrorBack,
      nextSailErrorThere,
      nextSailErrorBack,
    ].find(Boolean)

    const prevError = prevErrorRef.current

    if (Boolean(error) !== prevError && typeof errorHandler === 'function') {
      errorHandler({
        error,
        time: new Date(),
        message: error ? getTranslation('bookingProcessError') : '',
      })
    }

    prevErrorRef.current = Boolean(error)
  }, [
    errorHandler,
    getTranslation,
    invErrorBack,
    invErrorThere,
    nextInvErrorBack,
    nextInvErrorThere,
    nextSailErrorBack,
    nextSailErrorThere,
    sailErrorBack,
    sailErrorThere,
  ])

  useEffect(() => {
    dispatch({
      type: SET_INVENTORY,
      payload: { direction: 'to', inventory: inventoryThere ?? defaultInventory },
    })
  }, [inventoryThere])

  useEffect(() => {
    dispatch({
      type: SET_NEXT_INVENTORY,
      payload: { direction: 'to', nextInventory: nextInventoryThere ?? defaultInventory },
    })
  }, [nextInventoryThere])

  useEffect(() => {
    dispatch({
      type: SET_INVENTORY,
      payload: { direction: 'from', inventory: inventoryBack ?? defaultInventory },
    })
  }, [inventoryBack])

  useEffect(() => {
    dispatch({
      type: SET_NEXT_INVENTORY,
      payload: { direction: 'from', nextInventory: nextInventoryBack ?? defaultInventory },
    })
  }, [nextInventoryBack])

  useEffect(() => {
    dispatch({
      type: SET_SAIL_CONTENT,
      payload: { direction: 'to', sailContent: sailContentThere ?? [] },
    })
  }, [sailContentThere])

  useEffect(() => {
    dispatch({
      type: SET_NEXT_SAIL_CONTENT,
      payload: { direction: 'to', nextSailContent: nextSailContentThere ?? [] },
    })
  }, [nextSailContentThere])

  useEffect(() => {
    dispatch({
      type: SET_SAIL_CONTENT,
      payload: { direction: 'from', sailContent: sailContentBack ?? [] },
    })
  }, [sailContentBack])

  useEffect(() => {
    dispatch({
      type: SET_NEXT_SAIL_CONTENT,
      payload: { direction: 'from', nextSailContent: nextSailContentBack ?? [] },
    })
  }, [nextSailContentBack])

  useEffect(() => {
    const isLoading = [invLoadingThere, nexInvLoadingThere, nextSailLoadingThere, sailLoadingThere].some(Boolean)

    dispatch({ type: SET_LOADING, payload: { direction: 'to', loading: isLoading } })
  }, [invLoadingThere, nexInvLoadingThere, nextSailLoadingThere, sailLoadingThere])

  useEffect(() => {
    const isLoading = [invLoadingBack, nextInvLoadingBack, nextSailLoadingBack, sailLoadingBack].some(Boolean)

    dispatch({ type: SET_LOADING, payload: { direction: 'from', loading: isLoading } })
  }, [invLoadingBack, nextInvLoadingBack, nextSailLoadingBack, sailLoadingBack])

  useEffect(() => {
    if (routeBaseInfo?.titles) {
      const routeName = routeBaseInfo.titles[locale] || ''
      const [from = '', to = ''] = routeName.split(separatorDash).map((part: string) => part.trim())

      dispatch({ type: SET_PORTS, payload: { from, to } })
    }
  }, [routeBaseInfo?.titles, locale])

  useEffect(() => {
    if (availableDatesTo.length > 0 && there.availableDates.length === 0) {
      dispatch({ type: SET_AVAILABLE_DATES, payload: { direction: 'to', availableDates: availableDatesTo } })
    }
    if (availableDatesFrom.length > 0 && back.availableDates.length === 0) {
      dispatch({ type: SET_AVAILABLE_DATES, payload: { direction: 'from', availableDates: availableDatesFrom } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDatesTo.length, availableDatesFrom.length, packageIdThere, packageIdBack])

  const modInvThere = useMemo(
    () =>
      getModifiedInventory({
        inventory: there.inventory,
        nextInventory: there.nextInventory,
        sailContent: there.sailContent,
        nextSailContent: there.nextSailContent,
      }),
    [there.inventory, there.nextInventory, there.nextSailContent, there.sailContent]
  )

  const modInvBack = useMemo(
    () =>
      getModifiedInventory({
        inventory: back.inventory,
        nextInventory: back.nextInventory,
        sailContent: back.sailContent,
        nextSailContent: back.nextSailContent,
      }),
    [back.inventory, back.nextInventory, back.nextSailContent, back.sailContent]
  )

  useEffect(() => {
    setEarliestTimeAndRefId({
      defaultTimezone,
      direction: 'to',
      inventory: modInvThere,
      dispatch,
    })
  }, [defaultTimezone, modInvThere])

  const startTimestampForBack = useMemo(
    () =>
      getInitialTimestamp({
        startDay: there.date,
        startTime: there.time,
        isNextDay: there.isNextDay,
      }),
    [there.date, there.isNextDay, there.time]
  )

  useEffect(() => {
    setEarliestTimeAndRefId({
      defaultTimezone,
      direction: 'from',
      inventory: modInvBack,
      dispatch,
      initialTime: startTimestampForBack,
    })
  }, [defaultTimezone, modInvBack, startTimestampForBack])

  const handleDateChange = (direction: Direction) => async (date: Date) => {
    if (direction === 'to') {
      await updateUrlOnly({
        dates: state.hideRoundTripButton
          ? [
              convertToDateOnly(date),
              convertToDateOnly(date > state.sail.back.date ? date : state.sail.back.date),
            ].join(',')
          : convertToDateOnly(date),
      })
    } else {
      await updateUrlOnly({ dates: [convertToDateOnly(state.sail.there.date), convertToDateOnly(date)].join(',') })
    }
    dispatch({ type: SET_DATE, payload: { direction, date } })
  }

  const handleRefIdAndTimeChange = (direction: Direction) => (sailRefId: number, time: string, isNextDay: boolean) => {
    dispatch({ type: SET_REF_ID_AND_TIME, payload: { direction, refIdAndTime: { sailRefId, time, isNextDay } } })
  }

  const setHideRoundTripButton = async (hide: SetStateAction<boolean>) => {
    if (hide) {
      await updateUrlOnly({
        packages: [packageIdThere, packageIdBack].filter(Boolean).join(','),
        dates: [convertToDateOnly(state.sail.there.date), convertToDateOnly(state.sail.back?.date)].join(','),
      })
    } else {
      await updateUrlOnly({
        packages: packageIdThere,
        dates: convertToDateOnly(state.sail.there.date),
      })
    }
    dispatch({ type: SET_HIDE_ROUND_TRIP_BUTTON, payload: hide })
  }

  const vesselThere = getVessel({ inventory: modInvThere, sailRefId: there.sailRefId })
  const vesselBack = getVessel({ inventory: modInvBack, sailRefId: back.sailRefId })

  const packages = state.hideRoundTripButton ? [packageIdThere, packageIdBack] : [packageIdThere]
  const sailRefIds = state.hideRoundTripButton ? [there.sailRefId, back.sailRefId] : [there.sailRefId]

  const isDifferentShipsForRoute = state.hideRoundTripButton && !R.equals(vesselThere, vesselBack)
  const isDisabled = R.equals(there.sailRefId, -1) || isDifferentShipsForRoute || Boolean(errorBody)

  const hasDifferentShips = hasDifferentVessels(modInvThere, modInvBack)

  const handleSwapPorts = async () => {
    setSailPackage({
      code: packageIdBack,
      backwardSailPackage: packageIdThere,
      roundtrip: routeBaseInfo?.roundtrip ?? false,
      sailRefs: [],
      route: routeBaseInfo?.route || '',
      title: (routeBaseInfo?.titles && reverseTitleParts(routeBaseInfo.titles).en) ?? '',
      titles: routeBaseInfo?.titles && reverseTitleParts(routeBaseInfo.titles),
      routeAttributes: routeBaseInfo?.routeAttributes,
    })
    const newFirstPackage = packageIdBack
    const newSecondPackage = packageIdThere
    const packagesParamList = state.hideRoundTripButton ? [newFirstPackage, newSecondPackage] : [newFirstPackage]
    await updateUrlOnly({ packages: packagesParamList.join(',') })
  }

  const travelThere = useMemo(
    () =>
      getTravelDuration({
        voyages: there.sailContent,
        nextVoyages: there.nextSailContent,
        sailRefId: there.sailRefId,
      }),
    [there.nextSailContent, there.sailContent, there.sailRefId]
  )

  const travelBack = useMemo(
    () =>
      getTravelDuration({
        voyages: back.sailContent,
        nextVoyages: back.nextSailContent,
        sailRefId: back.sailRefId,
      }),
    [back.nextSailContent, back.sailContent, back.sailRefId]
  )

  return (
    <div data-testid="schedule-wrapper">
      <RouteInfoSection
        inventory={modInvThere}
        isLoading={there.loading}
        routeInfo={{
          departurePort: there.port,
          arrivalPort: back.port,
          shipName: getVesselTitleLocalized({ inventory: modInvThere, sailRefId: there.sailRefId, locale }),
          duration: travelThere,
        }}
        swapPorts={handleSwapPorts}
        hideRoundtripButton={state.hideRoundTripButton}
        setHideRoundTripButton={setHideRoundTripButton}
        selectedDate={there.date}
        onDayClick={handleDateChange('to')}
        isDayDisabled={checkIsDayDisabled({ availableDates: there.availableDates })}
        time={there.time || ''}
        isNextDay={there.isNextDay}
        sailRefId={there.sailRefId}
        setRefIdAndTime={handleRefIdAndTimeChange('to')}
        hasDifferentShips={hasDifferentShips}
        routeAttributes={routeBaseInfo?.routeAttributes}
        preventUndefinedPackageID={Boolean(packageIdBack)}
      />

      {Boolean(packageIdBack) && state.hideRoundTripButton && (
        <RouteInfoSection
          dataTestId="departure-back-card"
          inventory={modInvBack}
          isLoading={back.loading}
          routeInfo={{
            departurePort: back.port,
            arrivalPort: there.port,
            shipName: getVesselTitleLocalized({ inventory: modInvBack, sailRefId: back.sailRefId, locale }),
            duration: travelBack,
          }}
          swapPorts={handleSwapPorts}
          setHideRoundTripButton={setHideRoundTripButton}
          showDivider
          showCancelButton
          hideRoundtripButton
          selectedDate={back.date || there.date}
          onDayClick={handleDateChange('from')}
          isDayDisabled={checkIsDayDisabled({
            availableDates: back.availableDates,
            disableBeforeDate: there.date,
          })}
          time={back.time || ''}
          isNextDay={back.isNextDay}
          sailRefId={back.sailRefId}
          setRefIdAndTime={handleRefIdAndTimeChange('from')}
          initialTimestamp={startTimestampForBack}
          routeAttributes={routeBaseInfo?.routeAttributes}
        />
      )}

      <BottomSliderContainer>
        <GoBackButton href="/" title={getTranslation('goBackChangeRoute')} />
        <SelectTicketButton
          disabled={isDisabled}
          packages={packages.filter(Boolean)}
          sailRefIds={sailRefIds.filter((id) => id >= 0)}
        />
      </BottomSliderContainer>
    </div>
  )
}

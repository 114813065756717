export const APP_ROUTES: Record<string, string> = {
  selectDateSail: '/select-date-sail',
  signup: '/auth/signup',
  login: '/auth/login',
  recovery: '/auth/recovery',
  selectTickets: '/select-tickets',
  reservations: '/my/reservations',
  passengersData: '/my/passengersData',
  details: '/my/details',
}

import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

interface FormFieldContainerProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  id: string
  maxWidth?: 'lg' | 'full'
  isEditable?: boolean
  textLeft?: 'text-start' | 'text-end'
  isCustomerValidation?: boolean
}

export const FormFieldContainer: FC<FormFieldContainerProps> = ({
  label,
  children,
  id,
  maxWidth = 'lg',
  isEditable = true,
  textLeft = 'text-start',
  isCustomerValidation = false,
  ...rest
}) => {
  const showAsterisk =
    isEditable &&
    (isCustomerValidation
      ? !['gender', 'dateOfBirth'].includes(id)
      : !['firstName', 'lastName', 'gender', 'dateOfBirth'].includes(id))

  return (
    <div
      className={cn('w-full md:grid md:grid-cols-[25%_75%] justify-center', {
        'md:max-w-lg': maxWidth === 'lg',
        'md:max-w-full': maxWidth === 'full',
      })}
      {...rest}
    >
      <label
        htmlFor={id}
        data-testid={`label-${id}`}
        className={cn('w-full max-w-40 pt-3 md:pe-2 lg:pe-0 self-start', {
          'text-start': textLeft === 'text-start',
          'text-end': textLeft === 'text-end',
          'text-base-normal-lighter': textLeft === 'text-end',
          'text-[15px]': textLeft === 'text-end',
        })}
      >
        {label}
        {showAsterisk && <strong className="text-alert-base">*</strong>}
      </label>
      <div className="w-full">{children}</div>
    </div>
  )
}

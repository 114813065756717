import React, { FC, PropsWithChildren } from 'react'
import { RowContainer } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/RowContainer/RowContainer'
import * as R from 'ramda'

export const CustomRowContainer: FC<PropsWithChildren> = ({ children }) => {
  const childrenArray = React.Children.toArray(children)

  const firstTwoElements = R.take(2, childrenArray).map((node, index) => <div key={`first-${index}`}>{node}</div>)
  const lastTwoElements = R.takeLast(2, childrenArray).map((node, index) => <div key={`last-${index}`}>{node}</div>)

  return (
    <RowContainer className="grid grid-cols-1 gap-2 xs:grid-cols-[25%_25%_45%_5%]">
      <div className="col-span-2 flex justify-between p-2 xs:grid xs:grid-cols-[50%_50%] xs:gap-4 sm:col-span-2 sm:p-0">
        {firstTwoElements}
      </div>

      <div className="flex justify-between p-2 xs:grid xs:grid-cols-[80%_20%] xs:gap-2 sm:col-span-1 sm:p-0">
        {lastTwoElements}
      </div>
    </RowContainer>
  )
}

import React, { HTMLAttributes, FC } from 'react'
import cn from 'classnames'

const SearchBarCell: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return (
    <div className={cn('flex lg:border-e-2 lg:border-neutral-very-light lg:last:border-none', className)}>
      {children}
    </div>
  )
}

export default SearchBarCell

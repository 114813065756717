import React, { FC } from 'react'
import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import { SailPackage } from '@/root/shared-types'
import { useGetRouteSearchBarProps } from '@/root/shared-hooks/useGetRouteSearchBarProps/useGetRouteSearchBarProps'
import { Button, Checkbox } from '@/root/ui/admaritime'
import { CellContent } from '@/root/modules/admaritime/RouteSearchBar/components/CellContent/CellContent'
import { Dropdown } from '@/root/components/admaritime'
import SearchBarCell from '@/root/modules/admaritime/RouteSearchBar/components/SearchBarCell/SearchBarCell'
import { SearchbarDayPickers } from '@/root/modules/admaritime/RouteSearchBar/components/SearchbarDayPickers/SearchbarDayPickers'

interface RouteSearchBarComponentProps {
  sailPackages: SailPackage[]
}

const RouteSearchBar: FC<RouteSearchBarComponentProps> = ({ sailPackages }) => {
  const { getTranslation } = useGetTranslations()

  const { locale } = useCustomRouter()

  const {
    handleDateChange,
    selectedDate,
    isRoundTripChecked,
    onRoundTripCheck,
    dropdownProps,
    selectedRoute,
    availableDates,
    onGoToSelectTicket,
    isDayPickerOpened,
    toggleDayPickers,
  } = useGetRouteSearchBarProps(sailPackages)

  return (
    <>
      <section className="flex w-full flex-col rounded-md bg-white lg:grid lg:min-h-20 lg:w-full lg:grid-cols-3 xl:grid-cols-[30%_30%_40%]">
        <SearchBarCell>
          <CellContent title={getTranslation('searchBarWhereLabel')} contentClassName="-mt-4 lg:mt-0">
            <Dropdown
              variant="default"
              {...dropdownProps}
              selectedOption={selectedRoute}
              handleOptionSelection={(route) => {
                dropdownProps.handleOptionSelection(route)
                toggleDayPickers()
              }}
              contextClass="route-search-dropdown"
            />
          </CellContent>
        </SearchBarCell>
        <SearchBarCell>
          <CellContent title={getTranslation('searchBarWhenLabel')} contentClassName="-mt-4 ps-5 lg:mt-0">
            <SearchbarDayPickers
              selectedDate={selectedDate}
              availableDates={availableDates}
              isRoundTrip={isRoundTripChecked}
              onDateChange={handleDateChange}
              titleTo={selectedRoute.name[locale]}
              titleFrom={selectedRoute.extraProps?.backwardsName && selectedRoute.extraProps?.backwardsName[locale]}
              isOpened={isDayPickerOpened}
              toggleDayPickers={toggleDayPickers}
            />
          </CellContent>
        </SearchBarCell>
        <SearchBarCell className="lg:justify-between">
          <CellContent
            title={getTranslation('searchBarRoundTrip')}
            className="mb-3 mt-1.5 grid-cols-[auto_1fr] grid-rows-none gap-3 lg:my-0 lg:grid-cols-none lg:grid-rows-[40px_minmax(32px,auto)] lg:gap-0 lg:ltr:max-w-40 lg:rtl:max-w-36"
          >
            <Checkbox
              checked={isRoundTripChecked}
              variant="success"
              onChange={onRoundTripCheck}
              disabled={!selectedRoute.extraProps?.backwardsSailPackage}
              className="h-6 ps-5 rtl:items-center"
            />
          </CellContent>
          <Button
            variant="contained"
            className="hidden min-h-20 w-full rounded-none rounded-e-md border text-xl lg:block ltr:max-w-48 rtl:max-w-60"
            disabled={Boolean(!selectedDate.to)}
            onClick={onGoToSelectTicket}
          >
            {getTranslation('searchBarGetTickets')}
          </Button>
        </SearchBarCell>
      </section>
      <Button
        variant="contained"
        className="h-fit w-full border text-xl lg:hidden"
        disabled={Boolean(!selectedDate.to)}
        onClick={onGoToSelectTicket}
      >
        {getTranslation('searchBarGetTickets').toUpperCase()}
      </Button>
    </>
  )
}

export default RouteSearchBar

import { UserIcon } from '@/root/ui/shared/icons/UserIcon'
import { FC } from 'react'
import { useGetPassengersData, useGetTranslations } from '@/root/shared-hooks'

const CustomerDetailsHeader: FC = () => {
  const { getTranslation } = useGetTranslations()
  const { data } = useGetPassengersData()

  const fullName = data?.passengers?.[0]?.fullName ?? 'User'

  return (
    <div className="flex flex-col gap-2 ">
      <div className="flex gap-5">
        <UserIcon />
        <label className="text-primary-base pt-2 text-lg font-bold uppercase">{fullName}</label>
      </div>
      <label className="text-neutral px-10 text-sm">{getTranslation('customerSectionText')}</label>
    </div>
  )
}

export default CustomerDetailsHeader

import { FC } from 'react'
import cn from 'classnames'

interface TabProps {
  title: string
  setTab(tab: string): void
  isActive?: boolean
}

export const Tab: FC<TabProps> = ({ title, isActive, setTab }) => {
  return (
    <h1
      onClick={() => setTab(title)}
      className={cn(
        'cursor-pointer text-sm xs:text-base max-w-[100px] xs:max-w-full xs:w-fit leading-none text-base-normal-lighter',
        {
          'font-bold xs:border-b-4 xs:border-b-secondary text-white xs:text-primary-base': isActive,
          'xs:text-primary-base text-white xs:font-normal xs:pb-2': !isActive,
        }
      )}
    >
      {title}
    </h1>
  )
}

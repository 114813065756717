import { FC, FormEvent, InputHTMLAttributes, useState } from 'react'
import cn from 'classnames'
import { FieldErrors, FieldValues, UseFormRegister, UseFormTrigger } from 'react-hook-form'
import { useGetTranslations } from '@/root/shared-hooks'

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  trigger: UseFormTrigger<FieldValues>
  validationPattern?: RegExp
  validate?: (value: string) => true | string
  isCustomerDetails?: boolean

  onBlur?(): void
}

interface ErrorMessages {
  required: string
  pattern: string
  date: string
}

export const FormField: FC<FormInputProps> = ({
  id,
  className,
  register,
  placeholder,
  errors,
  trigger,
  validationPattern,
  inputMode,
  type,
  onBlur,
  isCustomerDetails = true,
  ...rest
}) => {
  const { getTranslation } = useGetTranslations()

  const [touched, setTouched] = useState(false)

  const handleInput = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement

    if (inputMode === 'numeric') {
      target.value = target.value.replace(/(?!^)\D/g, '')

      if (!target.value.startsWith('+')) {
        target.value = target.value.replace(/\D/g, '')
      }

      target.value = target.value.replace(/^0+/, '')

      if (target.value.startsWith('+0')) {
        target.value = '+' + target.value.slice(2)
      }
    }

    trigger(id)
  }

  const errorMessages: ErrorMessages = {
    required: getTranslation('confirmationContactFormPhoneRequiredError'),
    pattern:
      type === 'email'
        ? getTranslation('confirmationContactFormInvalidEmailError')
        : getTranslation('confirmationContactFormInvalidFormatError'),
    date: getTranslation('profileBirthDateInvalidFormatText'),
  }

  if (id) {
    const today = new Date().toISOString().split('T')[0]
    const dateProps = type === 'date' ? { min: '1923-01-01', max: today } : {}

    const errorType = errors[id]?.type as keyof ErrorMessages

    const inputPlaceholder = isCustomerDetails ? placeholder : undefined

    return (
      <span className={cn('flex min-h-16 gap-1 flex-col', className)}>
        <input
          type={type}
          id={id}
          {...dateProps}
          {...register(id, {
            required: touched,
            pattern: validationPattern,
          })}
          {...rest}
          placeholder={inputPlaceholder}
          className={cn(
            'h-12 rounded border border-neutral-normal-lighter p-2',
            { 'placeholder-transparent': !isCustomerDetails },
            className
          )}
          required={touched}
          onInput={handleInput}
          onBlur={() => {
            setTouched(true)
            trigger(id)
            if (onBlur) {
              onBlur()
            }
          }}
        />
        {touched && errorType && (
          <p className="text-alert-base flex justify-start text-xs">{errorMessages[errorType]}</p>
        )}
      </span>
    )
  }
}

import { FC } from 'react'
import { Customer as CustomerProps, Group } from '@/root/shared-types'
import { GroupBadge, KebabButton } from '@/root/ui/admaritime'
import ProfileButton from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/ProfileButon/ProfileButton'
import { useCreateOrEditFamily, useGetTranslations, useModal } from '@/root/shared-hooks'
import { ProfilePopUp } from '@/root/components/admaritime/ProfilePopUp/ProfilePopUp'
import { TableCell } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/TableCell/TableCell'
import { KebabMenu } from '@/root/components/admaritime'
import { formatedDateToAnotherFormat } from '@/root/shared-helpers'
import { CustomRowContainer } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/CustomRowContainer/CustomRowContainer'
import { dateFormats } from '@/root/shared-consts'

interface CustomerPropsWithGroups extends CustomerProps {
  groups: Group[]
  isOwner?: boolean
}

const Customer: FC<CustomerPropsWithGroups> = ({ groups, isOwner, ...customer }) => {
  const { fullName, birthday, companies, customerId } = customer
  const { getTranslation } = useGetTranslations()
  const { setModalBody } = useModal()
  const { deletePassenger } = useCreateOrEditFamily()

  return (
    <CustomRowContainer>
      <TableCell className="w-full">
        <span className="flex flex-wrap gap-1">
          {fullName} {isOwner && <p>({getTranslation('profileOwnerMe')})</p>}
        </span>
      </TableCell>

      <TableCell className="w-full">
        {birthday && formatedDateToAnotherFormat(birthday, dateFormats.DD_MM_YYYY)}
      </TableCell>

      <span className="flex flex-wrap gap-2">
        {companies && companies.length > 0 ? (
          companies.map(({ legalName, companyId }) => <GroupBadge title={legalName} key={companyId} />)
        ) : (
          <ProfileButton
            onClick={() =>
              setModalBody(
                <ProfilePopUp type="addToGroup" customerId={customerId} groups={groups} customer={customer} />
              )
            }
            label={getTranslation('profileAddToGroupPopUpTitle')}
            className="text-normal h-10"
          />
        )}
      </span>

      <div className="flex min-h-5 min-w-5 cursor-pointer justify-end">
        <KebabMenu>
          <KebabButton
            onClick={() => setModalBody(<ProfilePopUp type="editPassenger" groups={groups} customer={customer} />)}
          >
            {getTranslation('profileButtonEdit')}
          </KebabButton>
          {!isOwner && (
            <KebabButton onClick={() => deletePassenger(customerId)}>
              {getTranslation('profileButtonRemove')}
            </KebabButton>
          )}
        </KebabMenu>
      </div>
    </CustomRowContainer>
  )
}

export default Customer

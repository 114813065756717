import Link, { LinkProps } from 'next/link'
import { BackIcon } from '@/root/ui/shared'

interface GoBackButtonProps extends LinkProps {
  title: string
}

export const GoBackButton = ({ href, title, ...rest }: GoBackButtonProps) => {
  return (
    <Link
      href={href}
      data-testid="go-to-prev"
      className="border-neutral-dark bg-neutral-lightest text-neutral-dark flex h-16 w-full max-w-96 items-center justify-center gap-5 rounded-lg border text-sm font-bold uppercase"
      dir="ltr"
      {...rest}
    >
      <BackIcon color="neutral-dark" />
      <span>{title}</span>
    </Link>
  )
}

import { FC, HTMLAttributes } from 'react'
import { PlusIcon } from '@/root/ui/shared/icons/PlusIcon'
import cn from 'classnames'
import { Button } from '@/root/ui/admaritime'

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string
}

const ProfileButton: FC<ButtonProps> = ({ label, className, onClick }) => (
  <Button className={cn('border flex h-10 rounded-md p-1 sm:p-4 items-center gap-2', className)} onClick={onClick}>
    <PlusIcon />
    <div className="text-secondary text-small sm:text-base whitespace-nowrap cursor-pointer pt-1 font-bold">
      {label}
    </div>
  </Button>
)

export default ProfileButton

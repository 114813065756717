import React, { FC, HTMLAttributes } from 'react'
import {
  CancelledIndicator,
  ProgressBar,
  getInventoryValues,
  getTextColorByThreshold,
} from '@/root/modules/shared/SelectDateTimePage'
import { CapacityTableColumns } from '@/root/shared-types'
import { DangerGoodsIcon } from '@/root/ui/shared/icons/DangerGoodsIcon'
import { NoTrucksIcon } from '@/root/ui/shared/icons/NoTrucksIcon'
import { AgreeIcon } from '@/root/ui/shared/icons/AgreeIcon'
import { AnchorIcon } from '@/root/ui/shared/icons/AnchorIcon'
import {
  DeckAvailability,
  CarDeckInventoryClass,
  PassengerDeckInventoryClass,
} from '@/root/shared-types/selectDatePage/inventory-types'
import * as R from 'ramda'
import cn from 'classnames'

interface CapacityTableRowProps extends HTMLAttributes<HTMLButtonElement> {
  isSelected: boolean
  time: string
  showColumns: CapacityTableColumns
  onChange: () => void
  warningIconsCountInRow: number
  carDeck: DeckAvailability<CarDeckInventoryClass>
  passengerDeck: DeckAvailability<PassengerDeckInventoryClass>
  isNextDay: boolean
  restrictedPrices: boolean
  hidePaxPassengersCapacity: boolean
}

export const CapacityTableRow: FC<CapacityTableRowProps> = ({
  isSelected,
  time,
  carDeck,
  passengerDeck,
  showColumns,
  onChange,
  warningIconsCountInRow,
  isNextDay,
  restrictedPrices,
  hidePaxPassengersCapacity,
  ...props
}) => {
  const { showPassenger, showBicycle, showCar, showTruck } = showColumns

  const { amount: passengerAmount, total: passengerTotal } = getInventoryValues({
    deckAvailability: passengerDeck,
    inventoryClass: 'DECK',
  })
  const { amount: carAmount, total: carTotal } = getInventoryValues({
    deckAvailability: carDeck,
    inventoryClass: 'CAR_DECK',
  })
  const { amount: bicycleAmount, total: bicycleTotal } = getInventoryValues({
    deckAvailability: carDeck,
    inventoryClass: 'BICYCLE',
  })

  const passengerColorClass = getTextColorByThreshold(passengerAmount, passengerTotal)
  const bicycleColorClass = getTextColorByThreshold(bicycleAmount, bicycleTotal)
  const carColorClass = getTextColorByThreshold(carAmount, carTotal)

  const dangerousGoods = carDeck?.dangerousGoods || passengerDeck?.dangerousGoods
  const status = carDeck?.status ?? passengerDeck?.status

  const closed = R.equals(status, 'CLOSED')
  const cancelledSail = R.equals(status, 'CANCELLED')
  const noHeavyTracks =
    restrictedPrices && carDeck?.availableInventoryClasses.some((item) => item.inventoryClass === 'CAR_DECK')

  const getAsterisk = (isNextDay: boolean) => (isNextDay ? '*' : '')

  return (
    <button
      data-testid="ticket-card"
      aria-pressed={isSelected}
      {...props}
      className={cn(
        'flex h-12 w-full cursor-pointer items-center justify-between gap-1 text-base font-bold uppercase leading-6 xxs:text-lg xs:text-xl sm:text-large',
        {
          'rounded-lg border border-secondary bg-secondary-lightest': isSelected,
          '!h-11 rounded-lg border bg-alert-lightest border-alert-base !cursor-not-allowed': cancelledSail,
          '!cursor-not-allowed': closed,
        }
      )}
      onClick={onChange}
      disabled={status !== 'OPEN'}
    >
      <input type="radio" name="capacity-row" checked={isSelected} onChange={onChange} className="hidden" />

      <div className="flex xs:gap-1 sm:gap-2">
        <div
          data-testid="sail-time"
          className={cn('ms-2.5 flex w-24 pt-1 text-sm xxs:w-28 xs:text-base sm:w-32 sm:text-lg', {
            'text-primary-base': !isSelected,
            'text-secondary': isSelected || isNextDay,
          })}
        >
          {isNextDay ? `${time} ${getAsterisk(isNextDay)}` : time}
          {closed && <span className="text-alert-base ms-0.5">*</span>}
        </div>

        <div
          data-testid="special-icons-for-this-sail"
          className={cn('flex items-center justify-center gap-1', {
            'w-5 sm:w-7': warningIconsCountInRow === 1,
            'w-12 sm:w-14': warningIconsCountInRow === 2,
            'w-16 sm:w-20': warningIconsCountInRow === 3,
          })}
        >
          {dangerousGoods && <DangerGoodsIcon />}
          {noHeavyTracks && <NoTrucksIcon />}
          {cancelledSail && <AnchorIcon />}
        </div>
      </div>

      {showPassenger && (
        <div data-testid="availability-bar-passenger" className="progress-bar-small">
          {cancelledSail ? (
            <CancelledIndicator />
          ) : (
            <>
              {R.isNotNil(passengerAmount) && !hidePaxPassengersCapacity && (
                <div className={cn('flex flex-col items-center', passengerColorClass)}>
                  <div>
                    {passengerAmount}
                    {passengerAmount === 0 && <span>*</span>}
                  </div>
                  <ProgressBar value={passengerAmount} max={passengerTotal} />
                </div>
              )}
            </>
          )}
        </div>
      )}

      {showBicycle && (
        <div data-testid="availability-bar-bicycle" className="progress-bar-small">
          {cancelledSail ? (
            <CancelledIndicator />
          ) : (
            <>
              {R.isNotNil(bicycleAmount) && (
                <div className={cn('flex flex-col items-center', bicycleColorClass)}>
                  <div>
                    {bicycleAmount}
                    {bicycleAmount === 0 && <span>*</span>}
                  </div>
                  <ProgressBar value={bicycleAmount} max={bicycleTotal} />
                </div>
              )}
            </>
          )}
        </div>
      )}

      {(showCar || showTruck) && (
        <div
          data-testid="availability-bar-car"
          className={cn(showCar && showTruck ? 'progress-bar-large' : 'progress-bar-small')}
        >
          {cancelledSail ? (
            <CancelledIndicator />
          ) : (
            <>
              {R.isNotNil(carAmount) && (
                <div className={cn('flex flex-col items-center', carColorClass)}>
                  {showCar !== showTruck && (
                    <div data-testid="car-amount">
                      {carAmount}
                      {carAmount === 0 && <span>*</span>}
                    </div>
                  )}
                  <ProgressBar value={carAmount} max={carTotal} hasCarAndTruck={showCar && showTruck} />
                </div>
              )}
            </>
          )}
        </div>
      )}

      <div className="me-1 flex w-5 justify-end xxs:me-2 xxs:w-6 xs:me-4 md:w-24 lg:w-6 xl:w-24">
        {isSelected && <AgreeIcon />}
      </div>
    </button>
  )
}

import { Inventory } from '@/root/shared-types'
import {
  CarDeckInventoryClass,
  DeckAvailability,
  InventoryClass,
  PassengerDeckInventoryClass,
} from '@/root/shared-types/selectDatePage/inventory-types'

interface SoldOutStatus {
  soldOutPassenger: boolean
  soldOutBicycle: boolean
  soldOutCar: boolean
}

interface CheckSoldOutParams {
  availabilities: Record<string, DeckAvailability<PassengerDeckInventoryClass | CarDeckInventoryClass>>
  inventoryClass: InventoryClass
}

export const hasSoldOut = (inventory?: Inventory): SoldOutStatus => {
  if (!inventory) {
    return { soldOutPassenger: false, soldOutBicycle: false, soldOutCar: false }
  }

  const checkSoldOut = ({ availabilities, inventoryClass }: CheckSoldOutParams): boolean =>
    Object.values(availabilities).some(
      (avail) =>
        avail.availableInventoryClasses.some((item) => item.amount === 0 && item.inventoryClass === inventoryClass) &&
        avail.status !== 'CANCELLED'
    )

  const { passengerDecksAvailabilities: passengerDeck, carDecksAvailabilities: carDeck } = inventory

  return {
    soldOutPassenger: checkSoldOut({ availabilities: passengerDeck, inventoryClass: 'DECK' }),
    soldOutBicycle: checkSoldOut({ availabilities: carDeck, inventoryClass: 'BICYCLE' }),
    soldOutCar: checkSoldOut({ availabilities: carDeck, inventoryClass: 'CAR_DECK' }),
  }
}

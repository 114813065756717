import React, { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

interface CellContentProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  titleClassName?: string
  contentClassName?: string
}

export const CellContent: FC<CellContentProps> = ({
  title,
  children,
  titleClassName,
  contentClassName,
  className,
  ...rest
}) => (
  <div className={cn('grid w-full grid-rows-[40px_minmax(32px,auto)] items-center lg:gap-2', className)} {...rest}>
    <p className={cn('text-neutral-dark ps-5 text-lg font-bold uppercase', titleClassName)}>{title}</p>
    <div className={contentClassName}>{children}</div>
  </div>
)

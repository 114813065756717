import { FC, HTMLAttributes } from 'react'
import { AppLayout, CustomerHeader } from '@/root/modules/admaritime'

const CustomerPageLayout: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return (
    <AppLayout>
      <div className="flex justify-center xs:mt-10 xs:pb-20">
        <div className="bg-neutral-background-profile min-h-40 w-full rounded-md md:w-[1160px]">
          <CustomerHeader />
          <div className="flex min-h-36 w-full items-center justify-center p-7">{children}</div>
        </div>
      </div>
    </AppLayout>
  )
}

export default CustomerPageLayout

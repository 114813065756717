import { FC, useState } from 'react'
import { useGetProfilePopUpProps } from '@/root/components/admaritime/ProfilePopUp/hooks/useGetProfilePopUpProps'
import { CustomerFormField, Dropdown, RadioInput } from '@/root/components/admaritime'
import { Customer, Group, Member } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import { CrossIcon } from '@/root/ui/shared/icons/CrossIcon'
import { Loader } from '@/root/ui/shared'
import { ActionButton } from '@/root/components/admaritime/ProfilePopUp/components/ActionButton'
import { Control, useForm } from 'react-hook-form'
import { PassengerDetailsState } from '@/app/components/PassengerDetailsDialog'

type AddToGroupPopUp = { type: 'addToGroup'; customerId: number; groups: Group[]; customer: Customer }
type AddNewGroupPopUp = { type: 'addNewGroup' | 'editGroup'; customers: Customer[]; members?: Member[]; group?: Group }
type AddNewPassengerPopUp = {
  type: 'addNewPassenger' | 'editPassenger'
  groups: Group[]
  customer?: Customer
  placeholder?: string
  className?: string
}

export const ProfilePopUp: FC<AddToGroupPopUp | AddNewGroupPopUp | AddNewPassengerPopUp> = (props) => {
  const { getTranslation } = useGetTranslations()
  const popUpProps = useGetProfilePopUpProps(props)
  const { control, register } = useForm<PassengerDetailsState>({
    defaultValues: {
      gender: 'M',
    },
  })

  const { type } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleDropdown = () => setIsOpen((prev) => !prev)

  const isGroupPopup = ['addNewGroup', 'editGroup'].includes(type)

  const shouldDisplaySelectedOptionsList =
    ['addToGroup', 'addNewPassenger', 'editPassenger'].includes(type) || isGroupPopup

  if (type === 'addToGroup' && popUpProps) {
    const {
      fieldsIds,
      errors,
      trigger,
      fieldValuesContent,
      dropdownProps,
      title,
      disabled,
      isLoading,
      selectedDate,
      onDayClick,
    } = popUpProps

    const hasErrors = Object.keys(errors).length > 0

    return (
      <div className="xxs:w-min-[300px] w-min-[380px]  flex h-fit flex-col items-start gap-5 sm:w-[600px]">
        <span className="border-neutral-normal-lighter flex w-full justify-between border-b pb-6">
          <h1 className="text-lg font-bold text-adm-blue-800">{title}</h1>
        </span>
        {fieldsIds &&
          fieldValuesContent &&
          fieldsIds.map((id) => (
            <div key={id} className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
              <CustomerFormField
                control={control as Control<PassengerDetailsState>}
                id={id}
                {...fieldValuesContent[id]}
                errors={errors}
                trigger={trigger}
                selectedDate={selectedDate}
                onDayClick={onDayClick}
                register={register}
                maxWidth="full"
                isCustomerDetails
              />
            </div>
          ))}

        <p>{getTranslation('profileAddToGroupPopUpBelongsToGroups')}</p>
        <span className="border-neutral-normal-lighter w-full pb-5">
          <Dropdown
            {...dropdownProps}
            isOpen={isOpen}
            variant="filterSelected"
            handleToggleDropdown={handleToggleDropdown}
            disableFilter={true}
            contextClass="add-to-group-dropdown"
            className="border-neutral-normal-lighter rounded border"
            displaySelectedOptions={true}
          />

          {shouldDisplaySelectedOptionsList && (
            <div className="w-full">
              {dropdownProps.selectedOptions?.map((option) => (
                <div
                  key={option.id}
                  className="text-primary-base border-neutral-normal-lighter flex w-full justify-between border-b px-2 py-3 font-bold"
                >
                  <p>{option.name['en']}</p>
                  <div
                    onClick={() => dropdownProps.handleOptionSelection(option)}
                    className="text-error cursor-pointer"
                  >
                    <CrossIcon color="error" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {dropdownProps.options.length > 0 &&
            dropdownProps.options.some(
              (option) => !dropdownProps.selectedOptions.some((selected) => selected.id === option.id)
            ) && (
              <p className="border-neutral-normal-lighter border-b pb-6 pt-2 text-adm-gray-550">
                {getTranslation('profileAddToGroupPopUpHintText')}
              </p>
            )}
        </span>

        <ActionButton
          onClick={popUpProps.onClick}
          dataTestId="passenger-data-CE-button"
          disabled={isLoading || disabled || hasErrors}
        >
          {isLoading ? <Loader /> : getTranslation('profileAddToGroupPopUpButtonText')}
        </ActionButton>
      </div>
    )
  }

  if (
    ['addNewGroup', 'editGroup'].includes(type) ||
    (['addNewPassenger', 'editPassenger'].includes(type) && popUpProps)
  ) {
    const { title, fieldsIds, fieldValuesContent, control, errors, trigger, dropdownProps } = popUpProps
    const hasErrors = Object.keys(errors).length > 0

    return (
      <div className="xxs:w-min-[300px] w-min-[380px] flex h-fit flex-col items-start gap-5 sm:w-[600px]">
        <span className="border-neutral-normal-lighter flex w-full justify-between border-b pb-6">
          <h1 className="text-lg font-bold text-adm-blue-800">{title}</h1>
        </span>
        {fieldsIds &&
          fieldValuesContent &&
          fieldsIds.map((id) => {
            if (fieldValuesContent[id].type === 'radio') {
              return (
                <RadioInput
                  control={control}
                  key={id}
                  options={[
                    { id: 'M', name: getTranslation('genderMale') },
                    { id: 'F', name: getTranslation('genderFemale') },
                  ]}
                  label={fieldValuesContent[id].label}
                  id={id}
                />
              )
            }

            return (
              <CustomerFormField
                isCustomerDetails={false}
                control={control}
                key={id}
                id={id}
                {...fieldValuesContent[id]}
                errors={errors}
                trigger={trigger}
                maxWidth="full"
                selectedDate={popUpProps.selectedDate}
                onDayClick={popUpProps.onDayClick}
                register={register}
              />
            )
          })}

        <span className="flex w-full flex-col gap-2 overflow-visible py-4">
          <p>
            {type === 'addNewGroup'
              ? getTranslation('profileAddNewGroupPopUpAddPassenger')
              : getTranslation('profileAddToGroupPopUpBelongsToGroups')}
          </p>

          <Dropdown
            {...dropdownProps}
            isOpen={isOpen}
            variant="filterSelected"
            handleToggleDropdown={handleToggleDropdown}
            contextClass="add-new-group-dropdown"
            placeHolder={isGroupPopup ? getTranslation('profileAddNewGroupPlaceholder') : ''}
            className="border-neutral-normal-lighter min-h-12 rounded border"
            displaySelectedOptions={!isGroupPopup}
          />

          {shouldDisplaySelectedOptionsList && (
            <div className="w-full">
              {dropdownProps.selectedOptions?.map((option) => (
                <div
                  key={option.id}
                  className="text-primary-base border-neutral-normal-lighter flex w-full justify-between border-b px-2 py-3 font-bold"
                >
                  <p>{option.name['en']}</p>
                  <div
                    onClick={() => {
                      dropdownProps.handleOptionSelection(option)
                    }}
                    className="text-error cursor-pointer"
                  >
                    <CrossIcon color="error" />
                  </div>
                </div>
              ))}
            </div>
          )}
          {dropdownProps.options.length > 0 &&
            type === 'addNewPassenger' &&
            dropdownProps.options.some(
              (option) => !dropdownProps.selectedOptions.some((selected) => selected.id === option.id)
            ) && (
              <p className="border-neutral-normal-lighter border-b pb-6 text-adm-gray-550">
                {getTranslation('profileAddToGroupPopUpHintText')}
              </p>
            )}
        </span>

        <ActionButton
          onClick={popUpProps.onClick}
          dataTestId="passenger-data-CE-button"
          disabled={popUpProps.isLoading || popUpProps.disabled || hasErrors}
        >
          {popUpProps.isLoading ? <Loader /> : getTranslation('profileAddToGroupPopUpButtonText')}
        </ActionButton>
      </div>
    )
  }

  return null
}

import React, { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import { RouteCheckboxWhite } from '@/root/ui/shared/icons/RouteCheckboxWhite'

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  checked: boolean
  label?: string
  disabled?: boolean
  variant?: 'error' | 'success'
  colorLabel?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  disabled = false,
  variant = 'error',
  className,
  colorLabel = false,
}) => {
  return (
    <label
      className={cn(
        {
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
          'text-error': checked && variant === 'error' && colorLabel,
        },
        'flex gap-2',
        className
      )}
    >
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        className="absolute appearance-none opacity-0"
        onChange={onChange}
      />
      <div
        className={cn('min-w-5 w-5 h-5 border-2 rounded-sm flex items-center justify-center transition duration-300', {
          'bg-error shadow-lg': checked && variant === 'error',
          'bg-secondary shadow-lg': checked && variant === 'success',
          'border-neutral-normal-light': disabled,
          'border-error cursor-pointer': !disabled && variant === 'error',
          'border-secondary cursor-pointer': !disabled && variant === 'success',
        })}
      >
        <RouteCheckboxWhite className={cn({ hidden: !checked })} />
      </div>
      <p>{label}</p>
    </label>
  )
}

export default Checkbox

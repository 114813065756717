import { FC } from 'react'
import { CustomersTable } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/CustomersTable/CustomersTable'
import { GroupsTable } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/GroupsTable/GroupsTable'
import { Customer, Group } from '@/root/shared-types'

interface PassengersAndGroupsProps {
  groups: Group[]
  customers: Customer[]
}

const PassengersAndGroups: FC<PassengersAndGroupsProps> = ({ groups, customers }) => {
  return (
    <main className="w-full min-w-[380px]">
      <CustomersTable customers={customers} groups={groups} />
      <GroupsTable groups={groups} customers={customers} />
    </main>
  )
}

export default PassengersAndGroups

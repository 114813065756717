import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import CustomerPageLayout from './CustomerPageLayout'
import { AppLayout, PassengersAndGroups } from '@/root/modules/admaritime'
import { useGetPassengersData, useGetAuthToken, useGetTranslations } from '@/root/shared-hooks'
import { Loader } from '@/root/ui/shared'
import { ToastContainer } from 'react-toastify'
import { fetchCompaniesAndPassengers } from '@/root/shared-hooks/useGetPassengersData/useGetPassengersData'
import { Banner } from '@/root/ui/admaritime'
import { ContentContainer } from '@/root/components/shared'

const CustomerPassengersAndGroupsPage: FC = () => {
  const router = useRouter()
  const showPassengersAndGroups = router.pathname.includes('passengersData')

  const { isAuth } = useGetAuthToken()
  const { getTranslation } = useGetTranslations()
  const { data, isLoading, error, mutate } = useGetPassengersData(isAuth)

  useEffect(() => {
    if (isAuth) {
      mutate(fetchCompaniesAndPassengers(), false)
    }
  }, [isAuth, mutate])

  if (isLoading) {
    return (
      <CustomerPageLayout>
        <Loader />
      </CustomerPageLayout>
    )
  }

  if (error || !data) {
    return (
      <AppLayout>
        <div className="m-10 mx-auto flex max-w-7xl flex-col bg-white p-10">
          <Banner
            title={getTranslation('ticketBannerErrorTitle')}
            body={getTranslation('loginAuthGeneralError')}
            variant="error"
          />
        </div>
      </AppLayout>
    )
  }

  return (
    <CustomerPageLayout>
      {showPassengersAndGroups && data ? (
        <PassengersAndGroups groups={data.companies} customers={data.passengers} />
      ) : (
        <div className="text-center">{getTranslation('noDataAvailable')}</div>
      )}
      <ToastContainer />
    </CustomerPageLayout>
  )
}

export default CustomerPassengersAndGroupsPage

import { AvailableTheme, getMessages } from '@/lang'
import Link from 'next/link'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { takeAnother } from '../../helpers/takeAnother'
import { ContentContainer } from '@/root/components/shared'
import { MenuItem } from '@/root/shared-types'
import { useConfig, useCustomRouter } from '@/root/shared-hooks'
import { useOpenUserManual } from '@/root/shared-hooks/useOpenUserManual/useOpenUserManual'

interface TopHeaderMenuProps {
  menuItems: MenuItem[]
}

const TopHeaderMenu: FC<TopHeaderMenuProps> = ({ menuItems }) => {
  const { formatMessage } = useIntl()
  const { theme } = useConfig()
  const currentMessages = getMessages(theme as AvailableTheme)
  const { locale, locales, asPath } = useCustomRouter()
  const { onManualOpen } = useOpenUserManual()

  const nextLocale = takeAnother(locales as string[], locale)

  return (
    <div className="bg-primary-base h-8">
      <ContentContainer>
        <ul className="text-base-lightest flex w-full items-center justify-around py-2 text-xs xs:px-6 md:justify-end md:px-5">
          {menuItems.map(({ id, link, subject }) => {
            return (
              <li key={id} className="darken md:me-8">
                {link === 'userGuideModal' ? (
                  <button onClick={onManualOpen}>{subject}</button>
                ) : (
                  <Link href={link}>{subject}</Link>
                )}
              </li>
            )
          })}
          <li className="darken text-sm">
            <Link href={asPath} locale={nextLocale}>
              {formatMessage(currentMessages.headerLangSwitcher)}
            </Link>
          </li>
        </ul>
      </ContentContainer>
    </div>
  )
}

export default TopHeaderMenu

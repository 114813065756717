import { FC, ReactNode } from 'react'

interface IconTitleProps {
  icon: ReactNode
  title: string
}

const TableTitle: FC<IconTitleProps> = ({ icon, title }) => (
  <div className="mb-5 mt-8 flex gap-5 align-middle">
    {icon}
    <label className="text-primary-base pt-2 text-base font-bold uppercase">{title}</label>
  </div>
)

export default TableTitle

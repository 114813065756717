import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

export const BookingContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => (
  <div
    data-testid="booking-container"
    className={cn('mx-auto mt-10 mb-32 flex w-full max-w-screen-xl flex-col bg-white shadow-complex', className)}
  >
    {children}
  </div>
)

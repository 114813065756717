import { Inventory } from '@/root/shared-types'
import { DeckAvailability } from '@/root/shared-types/selectDatePage/inventory-types'
import * as R from 'ramda'

export const checkDangerousGoods = (inventory?: Inventory): boolean => {
  if (!inventory) {
    return false
  }

  const checkDangerousGoodsInDecks = <T>(decks: Record<string, DeckAvailability<T>>) =>
    Object.values(decks).some((deck) => deck.dangerousGoods === true)

  const hasDangerousGoodsInCarDecks = checkDangerousGoodsInDecks(inventory.carDecksAvailabilities)
  const hasDangerousGoodsInPassengerDecks = checkDangerousGoodsInDecks(inventory.passengerDecksAvailabilities)

  return hasDangerousGoodsInCarDecks || hasDangerousGoodsInPassengerDecks
}

export const maxWarningIconsInRow = (inventory?: Inventory): number => {
  if (!inventory) {
    return 0
  }
  const { carDecksAvailabilities: carDecks, passengerDecksAvailabilities: passengerDecks } = inventory

  const inventoryArray = Array.from(new Set([...Object.keys(carDecks), ...Object.keys(passengerDecks)])).map((id) => {
    const sailRefId = Number(id)

    return {
      sailRefId,
      carDeck: carDecks[sailRefId],
      passengerDeck: passengerDecks[sailRefId],
    }
  })

  const warningCounts = inventoryArray.map((item) => {
    const { carDeck, passengerDeck } = item

    const status = carDeck?.status ?? passengerDeck?.status
    const dangerousGoods = carDeck?.dangerousGoods || passengerDeck?.dangerousGoods
    const noHeavyTracks =
      carDeck?.restrictedPrices && carDeck?.availableInventoryClasses.some((item) => item.inventoryClass === 'CAR_DECK')

    return [dangerousGoods, R.equals(status, 'CANCELLED'), noHeavyTracks].filter(Boolean).length
  })

  return Math.max(0, ...warningCounts)
}

export const hasPassengerAvailabilities = (inventory?: Inventory): boolean => {
  if (!inventory) {
    return false
  }
  return Object.values(inventory.passengerDecksAvailabilities).some((avail) =>
    avail.availableInventoryClasses.some((item) => item.inventoryClass === 'DECK')
  )
}

export const hasBicycleAvailability = (inventory?: Inventory): boolean => {
  if (!inventory) {
    return false
  }
  return (
    Object.values(inventory.carDecksAvailabilities).some((avail) =>
      avail.availableInventoryClasses.some((item) => item.inventoryClass === 'BICYCLE')
    ) ||
    Object.values(inventory.passengerDecksAvailabilities).some((avail) =>
      avail.availableInventoryClasses.some((item) => item.inventoryClass === 'BICYCLE')
    )
  )
}

export const hasCarAvailabilities = (inventory?: Inventory): boolean => {
  if (!inventory) {
    return false
  }
  return Object.values(inventory.carDecksAvailabilities).some((avail) =>
    avail.availableInventoryClasses.some((item) => item.inventoryClass === 'CAR_DECK')
  )
}

export const checkNoHeavyTrucks = (inventory?: Inventory): boolean => {
  return inventory?.carDecksAvailabilities
    ? Object.values(inventory.carDecksAvailabilities).some(
        (avail) =>
          avail.restrictedPrices === true &&
          avail.availableInventoryClasses.some((item) => item.inventoryClass === 'CAR_DECK')
      )
    : false
}

interface BooleanAttributeParams {
  key: string
  routeAttributes?: Record<string, boolean | string>
}

export const getBooleanRouteAttribute = ({ routeAttributes, key }: BooleanAttributeParams): boolean | undefined =>
  typeof routeAttributes?.[key] === 'boolean' ? routeAttributes[key] : undefined

import { FC } from 'react'
import { Button } from '@/root/ui/admaritime'
import { BottomArrowIcon } from '@/root/ui/shared'
import { ButtonProps } from '@/root/ui/admaritime/Button/Button'
import { useGetTranslations } from '@/root/shared-hooks'
import router from 'next/router'
import { APP_ROUTES } from '@/root/shared-consts'

interface SelectTicketButtonProps extends ButtonProps {
  packages: Array<string>
  sailRefIds: Array<number>
}

export const SelectTicketButton: FC<SelectTicketButtonProps> = ({ disabled, packages, sailRefIds }) => {
  const { getTranslation } = useGetTranslations()

  const baseUrl = `${APP_ROUTES.selectTickets}`
  const packagesPath = `${packages.join(',')}`
  const refIdPath = `${sailRefIds.join(',')}`

  const url = `${baseUrl}/${packagesPath}/${refIdPath}`

  const onGoToSelectTicket = () =>
    router.push({
      pathname: url,
    })

  return (
    <Button
      variant="contained"
      className="order-first flex h-16 w-full max-w-96 items-center justify-between lg:order-last"
      disabled={disabled}
      onClick={onGoToSelectTicket}
      data-testid="go-to-next"
    >
      <p className="-ms-2 text-sm font-normal lowercase opacity-50">{getTranslation('continueLabel')}</p>
      <div className="-me-2 flex gap-3 xxs:gap-4">
        <p className="text-lg uppercase xxs:text-2xl">{getTranslation('selectTicketLabel')}</p>
        <BottomArrowIcon color="white" width={16} ignoreRtl className="ltr:-mt-1 ltr:-rotate-90 rtl:rotate-90" />
      </div>
    </Button>
  )
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ImageProps, RouteCardProps, RouteImages, SailPackagesGroups } from '@/root/shared-types'
import { useCustomRouter, useGetSailPackage, useGetTranslations } from '@/root/shared-hooks'
import { RouteCard } from '@/root/modules/admaritime/RouteButtonsSection/Components/RouteCard/RouteCard'
import { TaxiRoutesCard } from '@/root/modules/admaritime/TaxiRoutes/components/TaxiRoutesCard/TaxiRoutesCard'
import { useSortSailsPackages } from '@/root/pages/admaritime/hooks/useSortSailsPackages/useSortSailsPackages'
import { DateTime } from 'luxon'
import { dateFormats, APP_ROUTES } from '@/root/shared-consts'
import { LocalizedLocation, SailPackagesGroup } from '@/root/shared-types/reservation/reservation-types'

interface RouteButtonsSectionProps {
  routes: RouteImages[]
  sailPackagesGroups: SailPackagesGroup[]
}

export const RouteButtonsSection: FC<RouteButtonsSectionProps> = ({ routes, sailPackagesGroups }) => {
  const { getTranslation } = useGetTranslations()
  const [selectedRoute, setSelectedRoute] = useState<RouteCardProps<SailPackagesGroups>>()
  const [selectedSailPackage, setSelectedSailPackage] = useState<string>()
  const [image, setImage] = useState<ImageProps>()
  const router = useCustomRouter()
  const taxiRoutesCardRef = useRef<HTMLDivElement>(null)
  const { setSailPackage } = useGetSailPackage()

  useEffect(() => {
    const handleLocaleChange = (newLocale: string) => {
      if (newLocale !== router.locale) {
        setSelectedRoute(undefined)
      }
    }

    router.events.on('routeChangeComplete', handleLocaleChange)

    return () => {
      router.events.off('routeChangeComplete', handleLocaleChange)
    }
  }, [router.events, router.locale])

  const scrollToTaxiRoutesCard = useCallback(() => {
    if (selectedRoute && taxiRoutesCardRef.current) {
      taxiRoutesCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [selectedRoute])

  useEffect(() => {
    scrollToTaxiRoutesCard()
  }, [scrollToTaxiRoutesCard, selectedRoute])

  const onCardClick = (routeCard: RouteCardProps<SailPackagesGroups>) => {
    const { sailPackages, features, image, code, attributes } = routeCard
    const semiLoop = sailPackages.semiLoop[0]

    if (features?.showProducts) {
      setImage(image)
      setSelectedRoute(routeCard)
      setSelectedSailPackage(undefined)
    } else {
      const baseUrl = APP_ROUTES.selectDateSail
      const packageTo = semiLoop?.code ?? ''
      const dateTo = DateTime.now().toFormat(dateFormats.ISO_DATE)
      const url = `${baseUrl}/${packageTo}/${dateTo}`
      
      router.push({ pathname: url})
      setSailPackage({
        code: semiLoop?.code,
        backwardSailPackage: semiLoop?.backwardSailPackage,
        roundtrip: semiLoop?.roundtrip,
        sailRefs: [],
        route: code,
        title: semiLoop?.titles?.en || '',
        titles: semiLoop?.titles as LocalizedLocation,
        routeAttributes: attributes
      })
    }
  }

  const { data: routeGroups } = useSortSailsPackages(routes, sailPackagesGroups)

  return (
    <>
      <div className="text-primary-base flex w-full flex-wrap justify-around gap-10 rtl:flex-row-reverse">
        <div className="flex flex-col gap-6">
          <h1 className="flex justify-center text-2xl font-bold">{getTranslation('chooseFerryLine')}</h1>
          {routeGroups?.ferry?.map((routeCard) => (
            <RouteCard
              {...routeCard}
              attributes={routeCard.attributes} // TODO: Remove as soon as supportBicycles come from BE
              key={routeCard.code}
              onClick={() => onCardClick(routeCard)}
            />
          ))}
        </div>
        <div className="flex flex-col gap-6">
          <h1 className="flex justify-center text-2xl font-bold">{getTranslation('chooseWaterTaxi')}</h1>
          {routeGroups?.taxi?.map((routeCard) => (
            <RouteCard
              {...routeCard}
              key={routeCard.code}
              onClick={() => onCardClick(routeCard)}
              clicked={routeCard.code === selectedRoute?.code}
            />
          ))}
        </div>
      </div>
      {selectedRoute && selectedRoute.features.showProducts && (
        <div className="pt-5 lg:pt-10" ref={taxiRoutesCardRef}>
          <TaxiRoutesCard
            routeCard={selectedRoute}
            image={image}
            setImage={setImage}
            selectedRoute={selectedSailPackage}
            setSelectedRoute={setSelectedSailPackage}
          />
        </div>
      )}
    </>
  )
}

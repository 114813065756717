import {
  PassengerDeckInventoryClass,
  CarDeckInventoryClass,
  DeckAvailability,
  InventoryClass,
} from '@/root/shared-types/selectDatePage/inventory-types'

type GetInventoryValuesParams<T> = {
  deckAvailability: DeckAvailability<T>
  inventoryClass: InventoryClass
}

type InventoryValues = { amount?: number; total?: number }

export const getInventoryValues = <T extends PassengerDeckInventoryClass | CarDeckInventoryClass>({
  deckAvailability,
  inventoryClass,
}: GetInventoryValuesParams<T>): InventoryValues => {
  const inventoryItem = deckAvailability?.availableInventoryClasses.find(
    (item) => item.inventoryClass === inventoryClass
  )
  const { amount, total } = inventoryItem || {}

  return { amount, total }
}

import { ReservationItem } from '@/root/shared-types'
import Image from 'next/image'
import { FC } from 'react'
import { useIsMobile, useSetQrImgSrc, useScrollToRef } from '@/root/shared-hooks'

interface TicketItemsWithQRProps {
  items: ReservationItem[]
  reservationId: number
  sailRefId: number
}

const TicketItemsWithQR: FC<TicketItemsWithQRProps> = ({ items, reservationId, sailRefId }) => {
  const isMobile = useIsMobile()
  const { qrImgSrc } = useSetQrImgSrc({ sailRefId, reservationId })
  const { ref } = useScrollToRef(qrImgSrc)

  const length = isMobile ? 350 : 150

  return (
    <div className="flex flex-col items-start py-5 text-xl md:flex-row md:justify-between">
      <div className="flex flex-col space-y-4">
        {items.map(({ priceCategoryTranslation, quantity }) => {
          return (
            <div key={priceCategoryTranslation}>
              {quantity} x {priceCategoryTranslation}
            </div>
          )
        })}
      </div>
      {qrImgSrc && (
        <div data-testid="qr-code" className="self-center" ref={ref}>
          <Image alt="ticket qr code" src={qrImgSrc} width={length} height={length} />
        </div>
      )}
    </div>
  )
}

export default TicketItemsWithQR

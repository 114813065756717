import axios from 'axios'
import useSWR from 'swr'

export const fetchCompaniesAndPassengers = async () => {
  try {
    const {
      data: { content: companies },
    } = await axios({
      url: `/api/user/companies`,
    })

    const { data: passengers } = await axios({
      url: `/api/user/customers`,
    })

    return { companies, passengers }
  } catch (error) {
    console.error('Error fetching data:', error)
    return null
  }
}

export const useGetPassengersData = (isAuth?: boolean) => {
  const { data, error, isLoading, mutate } = useSWR(
    isAuth ? `passengersData:${isAuth}` : null,
    fetchCompaniesAndPassengers,
    { shouldRetryOnError: false }
  )

  return { data, error, isLoading, mutate }
}

import React, { FC, MouseEventHandler } from 'react'
import { RouteCardContainerButton } from '@/root/modules/admaritime/RouteButtonsSection/Components/RouteCardContainerButton/RouteCardContainerButton'
import RouteInfo from '../RouteInfo/RouteInfo'
import { ImageProps, SailPackagesGroups } from '@/root/shared-types'
import { SailPackagesGroup } from '@/root/shared-types/reservation/reservation-types'
import { RoutePicture } from '@/root/modules/admaritime/RouteButtonsSection/Components/RoutePicture/RoutePicture'
import { useCustomRouter } from '@/root/shared-hooks'
import * as R from 'ramda'

interface RouteCardProps extends SailPackagesGroup<SailPackagesGroups> {
  image?: ImageProps
  onClick?: MouseEventHandler<HTMLButtonElement>
  clicked?: boolean
  attributes?: Record<string, boolean | string>
}

export const RouteCard: FC<RouteCardProps> = ({
  from,
  to,
  taxi,
  features: { supportTrucks, supportPassengers, supportCars, supportBicycles },
  image,
  onClick,
  code,
  clicked = false,
  attributes,
}) => {
  const { locale } = useCustomRouter()

  const locationFrom = R.pathOr(`A-${code}`, [locale], from)
  const locationTo = R.pathOr(`B-${code}`, [locale], to)

  return (
    <RouteCardContainerButton onClick={onClick} clicked={clicked}>
      <RouteInfo
        locationFrom={locationFrom}
        locationTo={locationTo}
        isTaxi={taxi}
        supportTrucks={supportTrucks}
        supportCars={supportCars}
        supportPassengers={supportPassengers}
        supportBicycles={supportBicycles ?? Boolean(attributes?.SUPPORT_BICYCLES)} // TODO: Remove attributes?.SUPPORT_BICYCLES as soon as supportBicycles come from BE
      />
      {image && <RoutePicture image={image} />}
    </RouteCardContainerButton>
  )
}

import { getReferenceTime } from '@/root/modules/shared/SelectDateTimePage'
import { dateFormats, validStatuses } from '@/root/shared-consts'
import { Inventory, InventoryItem } from '@/root/shared-types'
import { DateTime } from 'luxon'
import * as R from 'ramda'

const { ISO_DATE, SIMPLE_TIME } = dateFormats

type InventoryArrayParams = {
  defaultTimezone: string
  inventory: Inventory
  initialTime?: DateTime
}

export const getInventoryAsArray = ({
  defaultTimezone,
  inventory,
  initialTime,
}: InventoryArrayParams): InventoryItem[] => {
  const carDecks = inventory.carDecksAvailabilities ?? {}
  const passengerDecks = inventory.passengerDecksAvailabilities ?? {}
  const departureDate = inventory.departureDate ?? ''

  const parsedDepartureDate = departureDate ? DateTime.fromFormat(departureDate, ISO_DATE) : undefined

  const referenceTime = getReferenceTime({
    defaultTimezone,
    initialTime,
  })

  const inventoryArray = Array.from(new Set([...Object.keys(carDecks), ...Object.keys(passengerDecks)])).map(
    (sailRefId) => {
      const carDeck = carDecks[Number(sailRefId)]
      const passengerDeck = passengerDecks[Number(sailRefId)]

      const rawTimestamp = carDeck?.timestamp || passengerDeck?.timestamp || ''
      const timestamp = rawTimestamp ? DateTime.fromISO(rawTimestamp) : undefined

      const status = carDeck?.status ?? passengerDeck?.status
      const restrictedPrices = Boolean (carDeck?.restrictedPrices ?? passengerDeck?.restrictedPrices)

      const isNextDay =
        parsedDepartureDate?.isValid && timestamp?.isValid
          ? timestamp.startOf('day') > parsedDepartureDate.startOf('day')
          : false

      return {
        sailRefId,
        carDeck,
        passengerDeck,
        timestamp,
        status,
        restrictedPrices,
        isNextDay,
      }
    }
  )

  return inventoryArray
    .filter((item) => item.timestamp && item.timestamp > referenceTime && R.any(R.equals(item.status), validStatuses))
    .sort((a, b) => (a.timestamp && b.timestamp ? a.timestamp.toSeconds() - b.timestamp.toSeconds() : 0))
    .map(({ timestamp, ...rest }) => ({
      ...rest,
      departureTime: timestamp ? timestamp.toFormat(SIMPLE_TIME) : '',
    }))
}
